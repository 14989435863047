
const i18n = {
    "error.unknown.room": 'Room does not exist',
    "error.name.required": 'Name is required',
    "error.room.required": 'Room is required',
    "error.game.cannot.start": 'Game cannot be started yet. Do you have enough players?',
    "error.game.not.started": 'The game has not started yet',
    "error.answer.required": 'That is not a valid answer',
    "error.invalid.phase": 'Cannot go to the next player yet',
    "error.not.owner": 'You are not the room owner',
    "error.name.taken": 'That name is already taken',
    "error.cannot.restart": "The room cannot be restarted. Is the game over yet?"
}

const getString = (s) => {
    if (i18n[s]) {
        return i18n[s]
    }
    return s
}

export default getString