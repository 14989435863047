import { PHASE_GAMEOVER, PHASE_WAITING_NEXT } from "../utils"
import GameOver from "./GameOver"
import PlayerList from "./PlayerList"
import Question from "./Question"
import RoomConfigurator from "./RoomConfigurator"


const Room = ({ room, isOwner, sendAction, version }) => {
    return <div className="room">
        { isOwner && !room.gameRunning && <RoomConfigurator room={room} sendAction={sendAction} version={version} /> }
        <PlayerList room={room} />
        { room.phase === PHASE_GAMEOVER && <GameOver room={room} isOwner={isOwner} sendAction={sendAction} /> }
        { room.phase !== PHASE_GAMEOVER && room.question && <Question room={room} question={room.question} sendAction={sendAction} currentAnswer={room.yourAnswer} />}

        {isOwner && room.phase === PHASE_WAITING_NEXT && <div className="controls"><button onClick={e => sendAction({ action: 'next' })}>Next question</button></div>}
    </div>
}

export default Room