import { useEffect, useState } from "react"

const RoomConfigurator = ({ room, sendAction, version }) => {
    const [rounds, setRounds] = useState(10)
    const [tags, setTags] = useState(null)
    const [types, setTypes] = useState({ 'multiple_choice': true, 'reverse': true, 'basic': true })

    const toggleTag = (t) => {
        const newTags = { ...tags }
        newTags[t] = !newTags[t]
        setTags(newTags)
    }
    const toggleType = (t) => {
        const newTypes = { ...types }
        newTypes[t] = !newTypes[t]
        setTypes(newTypes)
    }
    const getAllowed = (map) => {
        return Object.keys(map).filter(k => map[k])
    }

    useEffect(() => {
        if (version && version.tags) {
            setTags(version.tags.reduce((acc, tag) => ({ ...acc, [tag]: true }), {}))
        }
    }, [version])

    return <div className="room-config-wrapper">
        <div className="room-config">
            <label>
                Rounds per game:
                <select value={rounds} onChange={e => setRounds(e.target.value)}>
                    {[2, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(r => <option key={r} value={r}>{r}</option>)}
                </select>
            </label>
            <div>
                Allowed tags:
                <div className="tag-selector">
                    { tags && version && version.tags && version.tags.map(t => <label key={t}><input type="checkbox" checked={tags[t]} onChange={e => toggleTag(t)} /> {t}</label>)}
                </div>
            </div>
            <div>
                Allowed question types:
                <div className="tag-selector">
                    <label><input type="checkbox" checked={types['basic']} onChange={e => toggleType('basic')} /> Basic</label>
                    <label><input type="checkbox" checked={types['multiple_choice']} onChange={e => toggleType('multiple_choice')} /> Multiple choice</label>
                    <label><input type="checkbox" checked={types['reverse']} onChange={e => toggleType('reverse')} /> Reverse</label>
                </div>
            </div>
            <button onClick={e => sendAction({ action: 'start', data: { maxRounds: parseInt(rounds), allowedTags: getAllowed(tags), allowedTypes: getAllowed(types) } })}>Start game</button>
        </div>
    </div>
}

export default RoomConfigurator