import { useEffect, useState } from 'react'

const Question = ({ room, question, sendAction, currentAnswer }) => {

    if (question.type === 'multiple_choice') {
        return <MultipleChoiceQuestion question={question} sendAction={sendAction} currentAnswer={currentAnswer} />
    } else if (question.type === 'reverse') {
        return <ReverseQuestion room={room} question={question} sendAction={sendAction} currentAnswer={currentAnswer} />
    }
    return <BasicQuestion question={question} sendAction={sendAction} currentAnswer={currentAnswer} />
}

const BasicQuestion = ({ question, sendAction, currentAnswer }) => {
    return <div className="question-container">
        <div className="question">
            <div className="text">{question.question}</div>
            <div className="tags">{question.tags.map((t, i) => <div className="badge" key={i}>{t}</div>)}</div>
        </div>
        <div className="answers">{question.answers.map((a, i) => <div key={i} className={currentAnswer.includes(i) ? 'selected' : ''} onClick={e => sendAction({ action: 'answer', data: [i] })}>{a}</div>)}</div>
    </div>
}

const MultipleChoiceQuestion = ({ question, sendAction, currentAnswer }) => {
    const [answers, setAnswers] = useState([])

    const toggleAnswer = (i) => {
        if (answers.includes(i)) {
            setAnswers(answers.filter(a => a !== i))
        } else {
            setAnswers([...answers, i])
        }
    }
    useEffect(() => {
        setAnswers([])
    }, [question])

    return <div className="question-container">
        <div className="question">
            <div className="text">{question.question}</div>
            <div className="hint">This is a multiple choice question, you can select one or more answers. Correct answers are +1 point, incorrect answers are -1 point</div>
            <div className="tags">{question.tags.map((t, i) => <div className="badge" key={i}>{t}</div>)}</div>
        </div>
        <div className="answers">{question.answers.map((a, i) => <div key={i} className={answers.includes(i) || currentAnswer.includes(i) ? 'selected' : ''} onClick={e => toggleAnswer(i)}>{a}</div>)}</div>
        {currentAnswer.length === 0 && <div className="controls"><button onClick={e => sendAction({ action: 'answer', data: answers })}>Submit your answers</button></div>}
    </div>
}
const ReverseQuestion = ({ room, question, sendAction, currentAnswer }) => {

    const [answer, setAnswer] = useState('')
    useEffect(() => {
        setAnswer('')
    }, [question.question])

    return <div className="question-container">
        <div className="question">
            <div className="text">{question.question}</div>
            <div className="hint">This is a reverse question. Every player except the victim fills out an answer and then the victim picks the best answer. The winner gets 3 points.</div>
            <div className="tags">{question.tags.map((t, i) => <div className="badge" key={i}>{t}</div>)}</div>
        </div>
        {room.currentPlayer !== room.yourIndex && room.players[room.yourIndex].hasAnswered === false && <div className="answer-text">
            <div className="text-center"><input type="text" placeholder="Enter your answer" value={answer} onChange={e => setAnswer(e.target.value)} /></div>
            <div className="controls"><button onClick={e => sendAction({ action: 'answer_text', data: answer })}>Submit your answer</button></div>
        </div>}
        {room.currentPlayer === room.yourIndex && room.question.answers.length !== room.players.length - 1 && <div className="answer-text text-center">Waiting for all players to write their answers...</div> }
        {room.currentPlayer === room.yourIndex && room.question.answers.length === room.players.length - 1 && <div className="answers">{question.answers.map((a, i) => <div key={i} className={currentAnswer.includes(i) ? 'selected' : ''} onClick={e => sendAction({ action: 'answer', data: [i] })}>{a}</div>)}</div>}
    </div>
}

export default Question