import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlugCircleExclamation, faTimes } from '@fortawesome/free-solid-svg-icons'
import { PHASE_GAMEOVER, PHASE_WAITING_NEXT } from '../utils'

const PlayerList = ({ room }) => {
    return <div className="players">
        {room && room.players.map((player, i) => <Player key={player.name} room={room} player={player} isCurrentVictim={i === room.currentPlayer} />)}
    </div>
}

const countCorrectAnswers = (roomAnswers, userAnswers) => {
    let correctAnswers = 0
    roomAnswers.forEach((answer, i) => {
        if (userAnswers.includes(answer)) {
            correctAnswers++
        }
    })
    
    return correctAnswers - Math.abs(roomAnswers.length - userAnswers.length)
}

const Player = ({ room, player, isCurrentVictim }) => {

    let answerClass = ''
    if (room.currentAnswer.length > 0 && player.visibleAnswer.length > 0) {
        const correctAnswers = countCorrectAnswers(room.currentAnswer, player.visibleAnswer)
        console.log(correctAnswers)
        if (correctAnswers === room.currentAnswer.length) {
            answerClass = ' answered-correctly'
        } else if (correctAnswers > 0) {
            answerClass = ' answered-partially-correctly'
        } else {
            answerClass = ' answered-incorrectly'
        }
    }
    let victimClass = ''
    if (room.gameRunning) {
        victimClass = isCurrentVictim ? ' current-victim' : ''
    }
    return <div className={'player' + victimClass + answerClass}>
        <div className="name">
            {player.name}
            {player.connected === false && <FontAwesomeIcon icon={faPlugCircleExclamation} alt="Disconnected" />}
            {player.hasAnswered === true && <FontAwesomeIcon icon={faCheck} alt="Answered" className="ml-1" />}
        </div>
        <div className="points">{player.points} points</div>
        {room.question && room.question.type === 'multiple_choice' && <MultipleChoiceAnswer room={room} player={player} isCurrentVictim={isCurrentVictim} />}
        {room.question && room.question.type !== 'multiple_choice' && <SimpleAnswer room={room} player={player} isCurrentVictim={isCurrentVictim} />}
    </div>
}

const SimpleAnswer = ({ room, player, isCurrentVictim }) => {
    if (player.visibleAnswer.length === 0) {
        return <></>
    }
    if (room.phase !== PHASE_WAITING_NEXT && room.phase !== PHASE_GAMEOVER) {
        return <></>
    }
    return <div className="answer">{isCurrentVictim ? 'Correct answer' : 'Guessed'}: {room.question.answers[player.visibleAnswer[0]]}</div>
}
const MultipleChoiceAnswer = ({ room, player, isCurrentVictim }) => {
    if (player.visibleAnswer.length === 0) {
        return <></>
    }
    if (room.phase !== PHASE_WAITING_NEXT && room.phase !== PHASE_GAMEOVER) {
        return <></>
    }

    const AnswerIndicator = ({ answer }) => {
        if (room.currentAnswer.includes(answer)) {
            return <FontAwesomeIcon icon={faCheck} alt="Correct answer" className="correct" />
        } else {
            return <FontAwesomeIcon icon={faTimes} alt="Wrong" className="incorrect" />
        }
    }

    return <div className="answer">
        {isCurrentVictim ? 'Correct answer' : 'Guessed'}:
        {player.visibleAnswer.map((answer, i) => <div key={i}>{room.question.answers[answer]} {isCurrentVictim ? '' : <AnswerIndicator answer={answer} />}</div>)}
    </div>
}

export default PlayerList