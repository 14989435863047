

const GameOver = ({ room, sendAction, isOwner }) => {

    let sorted = room.players.sort((a,b) => b.points - a.points)

    return <div className="game-over">
        <h1>Game over!</h1>

        {sorted.map((p,i) => <div key={p.name} className="player">
            <div className="name">{i+1}: {p.name}</div>
            <div className="points">{p.points} points</div>
        </div>)}

        {isOwner && <button onClick={e => sendAction({ action: 'restart' })}>Go again!</button>}

    </div>
}

export default GameOver