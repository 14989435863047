

const VersionInfo = ({ version }) => {
    if (!version) {
        return <></>
    }
    return <div className="version-info">
        {version.revision} {version.buildTime}, {version.questionCount} questions
    </div>
}
export default VersionInfo