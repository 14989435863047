const StatusBar = ({ status, room, isOwner, sendAction }) => {

    if (!room) {
        return <></>
    }

    const copyToClipboard = (text) => {
        if (!window.navigator.clipboard) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy')
            } catch (e) { }
            document.body.removeChild(textArea)
        } else {
            window.navigator.clipboard.writeText(text)
        }
    }

    return <div className={'statusbar status-' + status}>
        Room <span className="room-id" onClick={e => copyToClipboard(room.id)}>{room.id}</span>
    </div>
}

export default StatusBar