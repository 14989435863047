import { useState } from "react"

const Login = ({ sendAction }) => {
    const [room, setRoom] = useState("")
    const [name, setName] = useState(window.localStorage.getItem('name') || "")

    const doJoin = () => {
        window.localStorage.setItem('name', name)
        sendAction({ action: 'join', data: { room: room, name: name } })
    }
    const doCreate = () => {
        window.localStorage.setItem('name', name)
        sendAction({ action: 'create', data: { name: name } })
    }

    return <div className="login-wrapper">
        <div className="login">
            <div className="login-header">
                <h1>Know Your friends</h1>
                <h2>How well do you know your friends?</h2>
            </div>
            <div className="mb-1">
                <div>
                    <input value={name} onChange={e => setName(e.target.value)} placeholder="What shall we call you?" />
                </div>
            </div>

            <div>
                <button onClick={e => doCreate()}>Create a room</button>
            </div>

            <div className="mb-1 mt-1 text-center">OR</div>

            <div className="mb-1">
                <input type="text" value={room} onChange={e => setRoom(e.target.value)} placeholder="Enter room code" className="mb-1" />
                <button onClick={e => doJoin()}>Join existing room</button>
            </div>
        </div>
    </div>
}

export default Login