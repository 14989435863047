import { useState } from "react";
import Login from "./components/Login";
import Room from "./components/Room";
import StatusBar from "./components/StatusBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWebSocket from 'react-use-websocket'
import getString from './i18n.js'
import VersionInfo from "./components/VersionInfo";

function App() {

  const [room, setRoom] = useState(null)
  const [status, setStatus] = useState("connecting")
  const [roomOwner, setRoomOwner] = useState(false)
  const [version, setVersion] = useState(null)

  const wsProtocol = document.location.protocol === 'https:' ? 'wss://' : 'ws://'
  const wsHost = document.location.host.startsWith('localhost') ? 'localhost:8080' : document.location.host
  const {
    sendMessage,
    getWebSocket,
  } = useWebSocket(wsProtocol+wsHost+'/ws', {
    onOpen: () => {
      console.log('ws open')
      onConnect()
      setStatus("connected")
    },
    onMessage: (data) => {
      onEvent(JSON.parse(data.data))
    },
    onClose: () => {
      console.log('Connection lost, reconnecting...')
      setStatus('reconnecting')
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => {
      console.log('ShouldReconnect?', closeEvent)
      return true
    },
  })

  window.wsref = getWebSocket()

  const sendAction = (action) => sendMessage(JSON.stringify(action))
  

  
  const onConnect = () => {
    if (window.localStorage.getItem('clientId')) {
      console.log('Reconnecting as ' + window.localStorage.getItem('clientId'))
      sendAction({ action: 'reconnect', data: window.localStorage.getItem('clientId') })
      setStatus('connected')
    } else {
      console.log('Connecting as new user')
      sendAction({ action: 'connect' })
      setStatus('connected')
    }
    sendAction({ action: 'versioninfo' })
  }
  const onEvent = (data) => {
    console.log(data)
    if (data.action === 'roomstate') {
      setRoom(data.data)
    } else if (data.action === 'connected') {
      console.log('Setting clientId to ' + data.data)
      window.localStorage.setItem('clientId', data.data)
      console.log('Room is',room)
      if (room && window.localStorage.getItem('name')) {
        console.log('Trying to rejoin room')
        sendAction({ action: 'join', data: { room: room.id, name: window.localStorage.getItem('name') } })
      }
    } else if (data.action === 'roomowner') {
      setRoomOwner(true)
    } else if (data.action === 'error') {
      toast.error(getString(data.data))
      if (data.data === 'error.unknown.room') {
        setRoom(null)
      }
    } else if (data.action === 'versioninfo') {
      setVersion(data.data)
    }
  }

  return (
    <div className="App">
      <StatusBar status={status} room={room} sendAction={sendAction} isOwner={roomOwner} />
      {room && <Room room={room} sendAction={sendAction} isOwner={roomOwner} version={version} />}
      {!room && <Login sendAction={sendAction} />}
      <VersionInfo version={version} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
